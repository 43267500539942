import styled from "@emotion/styled";

import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// import Box from "@mui/material/Box";

import { Helmet } from "react-helmet-async";

import {
  assignSessionTitle,
  getNextMessageStreaming,
  getSessions,
} from "../../functions/apiFunctions";
import { useGeneral } from "../../contexts/GeneralContext"; // Ensure the path is correct
import { SidePanel } from "../../components/chat/SidePanel";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import { DEBUG } from "../../functions/debug";
import RateLimitModal from "../../components/RateLimit";
import SettingsModal from "../../components/Settings";
import FeedbackModal from "../../components/FeedbackModal";
import { ChatUI } from "./ChatUI";
// import ImageIcon from "@mui/icons-material/Image";

// TODO: AB test the "tag line", <SmallText>Do more with the help of AI</SmallText>, vs. Get more projects done with
// less work, vs. Learn to DIY vs. "AI ToDo app"
const AuthenticatedChat = ({ abTests }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [sessions, setSessions] = useState([]);

  const [messages, setMessages] = useState([]);
  const [activeSessionId, setActiveSessionId] = useState(null);
  const { passedImage, passedPrompt, setPassedImage, setPassedPrompt } =
    useGeneral();
  const [inputValue, setInputValue] = useState("");
  const [image, setImage] = useState(null);

  const isMobile = window.innerWidth < 768;
  const [isPanelOpen, setIsPanelOpen] = useState(!isMobile);

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  const [showSettings, setShowSettings] = useState(false);
  const [isRateLimited, setIsRateLimited] = useState(false);
  const [hasSubscription, setHasSubscription] = useState(false);

  const [showFeedback, setShowFeedback] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (passedImage) {
      console.log("there was a passed image", passedImage);
      setImage(passedImage);
    }
  }, [passedImage]);

  const handleSendMessage = async (pPrompt, pImage) => {
    setIsLoading(true); // Set loading to true when starting
    const token = await getAccessTokenSilently();
    console.log("[handleSendMessage] pPrompt", pPrompt);
    console.log("[handleSendMessage] pImage", pImage);

    let contentArray = [];

    if (pPrompt) {
      contentArray.push({ type: "text", text: pPrompt });
    } else {
      contentArray.push({ type: "text", text: inputValue });
    }

    // If there's an image, include it in the content array
    if (image) {
      console.log("[handleSendMessage] image", image);
      contentArray.push({ type: "image_url", image_url: { url: image } });
    } else if (pImage) {
      console.log("[handleSendMessage] pImage", pImage);
      contentArray.push({ type: "image_url", image_url: { url: pImage } });
    }

    const userMessage = {
      role: "user",
      content: contentArray,
    };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInputValue("");
    setImage(null);

    if (DEBUG) console.log("----[stream]----");
    const response = await getNextMessageStreaming(
      token,
      userMessage,
      activeSessionId,
      abTests
    );
    console.log("response.body", response.body);

    if (response.status === 429) {
      console.error("Rate limit exceeded");
      setIsRateLimited(true);
      return;
    }

    if (!response.body) {
      console.error("No response body");
      return;
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");
    let sessionId;
    while (true) {
      setIsLoading(false);
      const { done, value } = await reader.read();
      if (done) {
        console.log("[stream][done] sessionId ==> ", sessionId);

        if (activeSessionId !== sessionId) {
          console.log("[stream][done] new session");
          setActiveSessionId(sessionId);
          const token = await getAccessTokenSilently();
          const result = await assignSessionTitle(token, sessionId);
          console.log("[stream][done] assignSessionTitle result ==> ", result);
          const title = result?.title;
          console.log("[stream][done] chat title ==> ", title);

          const newSession = { id: sessionId, title: title };
          setSessions((prevSessions) => {
            console.log("[stream][done] prevSessions ==> ", prevSessions);
            return [newSession, ...prevSessions];
          });
        }

        break;
      }

      const chunks = decoder.decode(value, { stream: true });
      if (DEBUG) console.log("[stream] chunk ==> ", chunks);

      // eslint-disable-next-line no-loop-func
      setMessages((prevMessages) => {
        try {
          if (chunks) {
            const chunkArray = chunks.split("\n");
            console.log("[stream] chunksString ==> ", chunkArray);

            for (const unprocessedChunk of chunkArray) {
              console.log("[stream] unprocessedChunk ==> ", unprocessedChunk);
              if (unprocessedChunk) {
                console.log("[stream] unprocessedChunk ==> ", unprocessedChunk);
                const chunks = JSON.parse(unprocessedChunk);
                console.log("[stream] cleanChunks ==> ", chunks);
                let content = chunks?.content;
                let messageId = chunks?.message_id;
                sessionId = chunks?.session_id;

                const existingMessageIndex = prevMessages.findIndex(
                  (msg) => msg.messageId === messageId
                );
                if (existingMessageIndex !== -1) {
                  prevMessages[existingMessageIndex].content += content;
                } else {
                  prevMessages.push({
                    content,
                    role: "assistant",
                    messageId: messageId,
                  });
                }
              } else {
                console.log("[stream] empty chunk");
              }
            }

            return [...prevMessages]; // This returns the updated list of messages.
          }
        } catch (e) {
          console.error("Error parsing line", e);
          return [...prevMessages];
        }
      });
    }
  };

  useEffect(() => {
    if (passedPrompt) {
      if (passedImage) {
        handleSendMessage(passedPrompt, passedImage); // Assuming you want to send the starter as a message
      } else {
        handleSendMessage(passedPrompt); // Assuming you want to send the starter as a message
      }
      setPassedImage(null);
    }
  }, []); // Empty dependency array to run only on initial load

  const getRemoteSessions = async () => {
    const token = await getAccessTokenSilently();
    getSessions(token, abTests).then((response) => {
      // console.log("[getRemoteSessions] response", response);
      const sessions = response?.sessions;
      const hasSubscription = response?.has_subscription;
      // console.log("[getRemoteSessions] sessions", sessions);
      // console.log("[getRemoteSessions] hasSubscription", hasSubscription);
      if (hasSubscription) {
        // console.log("has subscription");
        setHasSubscription(true);
      }
      if (sessions && sessions.length > 0) {
        // console.log("[getRemoteSessions] setting sessions variable");
        setSessions(sessions);
      }
    });
  };

  useEffect(() => {
    if (user) {
      getRemoteSessions();
    }
  }, [user]);

  // console.log("messages", messages);

  // ismobile variable please
  // BottomContainer MessageContainer MessagesContainer ChatContainer
  return (
    <Container>
      <Helmet>
        <title>Dooe Chat - An AI Assistant for home improvement </title>
        <meta
          name="description"
          content={`Dooe can help you with your home tasks. Message Dooe via the Chat!`}
        />
        <link rel="canonical" href={`https://www.dooe.io/chat`} />
      </Helmet>
      {isPanelOpen ? (
        <SidePanel
          isOpen={isPanelOpen}
          togglePanel={togglePanel}
          sessions={sessions}
          setMessages={setMessages}
          setSessionId={setActiveSessionId}
          setShowSettings={setShowSettings}
          setShowFeedback={setShowFeedback}
          abTests={abTests}
        />
      ) : (
        <ClosedSidePanel />
      )}
      {isRateLimited && <RateLimitModal hasSubscription={hasSubscription} />}
      {showSettings && (
        <SettingsModal
          hasSubscription={hasSubscription}
          setShowSettings={setShowSettings}
        />
      )}
      {showFeedback && <FeedbackModal setShowFeedback={setShowFeedback} />}

      <ChatUI
        messages={messages}
        isPanelOpen={isPanelOpen}
        togglePanel={togglePanel}
        isLoading={isLoading}
        inputValue={inputValue}
        handleSendMessage={handleSendMessage}
        image={image}
        isAuthenticated={true}
        setImage={setImage}
        setInputValue={setInputValue}
      />
    </Container>
  );
};

export default withAuthenticationRequired(AuthenticatedChat);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  overflow: hidden;

  @media (max-width: 768px) {
    /* Adjust breakpoint as needed */
    position: relative; /* Allows SidePanel to overlap */
  }
`;
const ClosedSidePanel = styled.div`
  width: 0px;
  background-color: #f2f2f2;
  transition: width 0.3s ease; // Smooth transition for width
`;
