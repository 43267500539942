export const customTaskLookup = {
  "Home Improvement Genius": {
    preAuthText:
      "Share more about the home improvement project you are working on and advanced AI will guide you through it.",
    image: true,
    optionalImage: true,
    textArea: true,
    textAreaPlaceholder: "Describe what you need help.",
    prompt: "As a Home Improvement Genius, help with the following request: ",
    icon: "🔨",
  },
  "Fix Anything": {
    preAuthText:
      "Share more about the item you are trying to fix and advanced AI will guide you through fixing it.",
    image: true,
    textArea: true,
    optionalImage: true,
    textAreaPlaceholder: "Describe the issue you need fixed...",

    prompt: "Help me fix the item in the image and considering the following: ",
    icon: "🔧",
  },

  "Super Handy Robot": {
    preAuthText:
      "Get help with your handy task with the guidance of Super Handy Robot, an advanced AI Home assistant.",
    image: true,
    textArea: true,
    textAreaPlaceholder: "Describe what task you'd like help with...",

    prompt: "You are a Super Handy Robot. Help with the following request: ",
    icon: "🤖",
    hideFromStartersAll: true,
  },

  "Healthy Meal Planner": {
    preAuthText:
      "The advanced AI analyzes your food preferences to create a meal plan for you!",
    image: false,
    textArea: true,
    textAreaPlaceholder: "Describe any meal preferences you have",

    prompt: "Help me plan my next meal based on the following input: ",
    icon: "🥦",
  },

  "Incredible Meal Planner": {
    preAuthText:
      "Take a picture of your fridge or pantry and advanced AI will give you meal ideas and recipes on how to make them.",
    image: true,
    textArea: true,
    textAreaPlaceholder: "Describe any meal preferences you have",

    prompt:
      "Based on the ingredients available in this image, help me plan my next meal and provide me recipes. Please also consider any following details: ",
    icon: "🥦",
  },
  "Plant Identifier": {
    preAuthText:
      "You will share an image of the plant and advanced AI will identify it and provide you with more information.",
    image: true,
    textArea: false,
    prompt: "Identify the plant in the image.",
    icon: "🌿",
  },
  "Cleaning Tips": {
    preAuthText:
      "Share details about your cleaning needs and advanced AI will provide expert tips.",
    image: true,
    optionalImage: true,
    textArea: true,
    textAreaPlaceholder: "Describe your cleaning needs...",
    prompt: "As a Cleaning expert, provide tips for the following request: ",
    icon: "🧼",
  },
  "Home Decorating Ideas": {
    preAuthText:
      "Based on the image you provide, advanced AI will suggest home decorating ideas.",
    image: true,
    optionalImage: true,
    textArea: true,
    textAreaPlaceholder: "Describe any decorating preferences you have",
    prompt:
      "Based on the image, suggest home decorating ideas. Please also consider the following: ",
    icon: "🏡",
  },
  "Interior Decorating Pro": {
    preAuthText:
      "Upload an image of your room and advanced AI will provide professional interior decorating advice.",
    image: true,
    optionalImage: true,
    textArea: true,
    textAreaPlaceholder: "Describe your decorating preferences or goals...",
    prompt:
      "Provide professional interior decorating advice. If an image is available, review that first. Please also consider the following: ",
    icon: "🏡",
  },
  "Interior Design Pro": {
    preAuthText:
      "Share an image of your space and advanced AI will offer expert interior design suggestions.",
    image: true,
    optionalImage: true,
    textArea: true,
    textAreaPlaceholder: "Describe your design preferences or requirements...",
    prompt:
      "Offer expert interior design suggestions. If an image is available, review that first. Please also consider the following: ",
    icon: "🏡",
    hideFromStartersAll: true,
  },
  "Home Decorating Genius": {
    preAuthText:
      "Ask for help related to home decorating and advanced AI will provide expert advice.",
    image: true,
    optionalImage: true,
    textArea: true,
    textAreaPlaceholder: "Describe what you need help with...",
    prompt:
      "As an home decorating genius offer expert advice. If an image is available, review that first. Please help with the following task: ",
    icon: "🏡",
    hideFromStartersAll: true,
  },
  "Home Maintenance Pro": {
    preAuthText:
      "Ask the Home Maintenance Pro for advice on anything related to home maintenance.",
    image: false,
    optionalImage: false,
    textArea: true,
    textAreaPlaceholder:
      "Describe what you need help with (home maintenance list, etc.)...",
    prompt: "As a Home Maintenance Pro, help with the following request: ",
    icon: "🔨",
    hideFromStartersAll: true,
  },
  "Home Improvement Tips": {
    preAuthText:
      "Share more about the home improvement project you are working on and advanced AI will guide you through it.",
    image: true,
    optionalImage: true,
    textArea: true,
    textAreaPlaceholder: "Describe what you need help.",
    prompt: "As a Home Improvement pro, help with the following request: ",
    icon: "🛠️",
    hideFromStartersAll: true,
  },
  "Home Maintenance Tips": {
    preAuthText:
      "Share details about your home maintenance needs and advanced AI will provide expert tips.",
    image: true,
    optionalImage: true,
    textArea: true,
    textAreaPlaceholder: "Describe your home maintenance needs...",
    prompt:
      "As a Home Maintenance expert, provide tips for the following request: ",
    icon: "🔧",
  },
};
