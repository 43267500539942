import React, { useState } from "react";
import styled from "@emotion/styled";

import { useParams, useNavigate } from "react-router-dom";
import { useGeneral } from "../contexts/GeneralContext"; // Ensure the path is correct
import { NavigationStatic } from "../components/NavigationStatic";
import Tools from "../images/robot-planting.png";

import { Helmet } from "react-helmet-async";
import { capitalizeWords } from "../functions/helpers";
import { useAuth0 } from "@auth0/auth0-react";
import { customTaskLookup } from "./customStarters";
import { ArrowRight, Upload, Tool, ChevronDown } from "lucide-react";
import { keyframes } from "@emotion/react";

export const Starters = () => {
  const DEBUG = false;
  const { task } = useParams();
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  // Function to capitalize the first letter of each word

  const taskTitle = capitalizeWords(task); // Apply the function to the task
  const taskConfig = customTaskLookup[taskTitle] || {};

  const navigate = useNavigate(); // Initialize the useNavigate hook
  const { setPassedImage, setPassedPrompt } = useGeneral();

  // State for image upload
  const [imageUploaded, setImageUploaded] = useState(false);
  // State for PrettyTextArea value
  const [textAreaValue, setTextAreaValue] = useState("");

  const startChatFromKit = () => {
    console.log("taskTitle", taskTitle);

    const taskConfig = customTaskLookup[taskTitle] || {};

    let input = "";
    console.log("taskConfig", taskConfig);
    if (textAreaValue && taskConfig.prompt) {
      input = taskConfig.prompt + textAreaValue;
    } else if (taskConfig.prompt) {
      input = taskConfig.prompt;
    } else {
      input = taskTitle;
    }
    setPassedPrompt(input);
    navigate(`/chat`);
  };

  // Handle image upload (this is just a mock, replace with actual upload logic)
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPassedImage(reader.result); // Set the uploaded image in the context
        setTimeout(() => {
          setImageUploaded(true); // Set imageUploaded to true after successful upload
        }, 1000);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleTextAreaChange = (event) => {
    setTextAreaValue(event.target.value);
  };

  const scrollToContent = () => {
    document
      .querySelector(".content-section")
      .scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Container>
      <Helmet>
        <title>{taskTitle} - Dooe Starter Packs</title>
        <meta
          name="description"
          content={`${taskTitle} - Dooe Starter Pack: AI-powered assistance for your home improvement projects`}
        />
        <link rel="canonical" href={`https://www.dooe.io/starters/${task}`} />
      </Helmet>
      <NavigationStatic isStarter={true} />

      <HeroSection onClick={scrollToContent}>
        <HeroTitle>{taskTitle}</HeroTitle>
        <HeroSubtitle>
          Unlock the power of AI to supercharge your home projects.
        </HeroSubtitle>
        <ScrollIndicator>
          <ChevronDown size={40} color="white" />
        </ScrollIndicator>
      </HeroSection>

      <Content className="content-section">
        <Card>
          <TaskIcon>{taskConfig.icon}</TaskIcon>
          <TaskTitle>{taskTitle}</TaskTitle>
          <TaskDescription>{taskConfig.preAuthText}</TaskDescription>

          {taskConfig.image && (
            <FileUploadContainer>
              <ImageUploadLabel htmlFor="upload-image">
                <Upload size={24} style={{ marginRight: "12px" }} />
                {taskConfig.optionalImage
                  ? "Upload Image (Optional)"
                  : "Upload Image"}
              </ImageUploadLabel>
              <input
                type="file"
                id="upload-image"
                onChange={handleImageUpload}
                style={{ display: "none" }}
                aria-label="Upload Image"
              />
            </FileUploadContainer>
          )}

          {taskConfig.textArea && (
            <PrettyTextArea
              placeholder={
                taskConfig.textAreaPlaceholder ||
                "Describe your project here..."
              }
              rows="5"
              maxLength={taskConfig.maxLength || 4000}
              value={textAreaValue}
              onChange={handleTextAreaChange}
              aria-label="Project Description"
            />
          )}

          {taskConfig.optionalImage || imageUploaded || !taskConfig.image ? (
            <PrettyButton onClick={startChatFromKit}>
              Start Your Project{" "}
              <ArrowRight size={24} style={{ marginLeft: "12px" }} />
            </PrettyButton>
          ) : (
            <GrayButton>Upload an image to continue</GrayButton>
          )}
        </Card>

        <AboutSection>
          <AboutTitle>Revolutionize Your Home Improvement</AboutTitle>
          <AboutText>
            Dooe's AI-powered Starter Packs are designed to streamline your home
            projects, providing expert guidance every step of the way. From
            planning to execution, our intelligent assistant helps you tackle
            your to-do list with ease, saving you time and ensuring
            professional-quality results.
          </AboutText>
        </AboutSection>

        <AdditionalContent>
          <AdditionalContentText>As featured in</AdditionalContentText>
          <SocialIcon
            src="https://cdn-icons-png.flaticon.com/512/733/733579.png"
            alt="Twitter Logo"
          />
          <SocialIcon
            src="https://cdn-icons-png.flaticon.com/512/2111/2111589.png"
            alt="Reddit Logo"
          />
        </AdditionalContent>
      </Content>
    </Container>
  );
};

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const Container = styled.div`
  background-color: #f8f9fa;
  min-height: 100vh;
  overflow-x: hidden;
`;

const HeroSection = styled.div`
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 15s ease infinite;
  padding: 150px 0 100px;
  text-align: center;
  color: white;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('data:image/svg+xml,%3Csvg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z" fill="%23ffffff" fill-opacity="0.1" fill-rule="evenodd"/%3E%3C/svg%3E');
    opacity: 0.5;
  }
`;

const HeroTitle = styled.h1`
  font-size: 4.5rem;
  font-weight: 800;
  margin-bottom: 20px;
  animation: ${fadeIn} 0.8s ease-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
`;

const HeroSubtitle = styled.p`
  font-size: 1.4rem;
  max-width: 700px;
  margin: 0 auto 40px;
  opacity: 0.9;
  animation: ${fadeIn} 0.8s ease-out 0.2s both;
  line-height: 1.6;
`;

const ScrollIndicator = styled.div`
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  animation: ${float} 1.5s ease-in-out infinite;
  cursor: pointer;
`;

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Card = styled.div`
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 60px;
  width: 100%;
  max-width: 800px;
  margin-bottom: 80px;
  animation: ${fadeIn} 0.8s ease-out 0.4s both;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
  }
`;

const TaskIcon = styled.div`
  font-size: 4rem;
  margin-bottom: 30px;
  color: #23a6d5;
`;

const TaskTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
`;

const TaskDescription = styled.p`
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 40px;
  line-height: 1.6;
`;

const FileUploadContainer = styled.div`
  margin-bottom: 30px;
`;

const ImageUploadLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 25px;
  background-color: #f0f0f0;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;

  &:hover {
    background-color: #e0e0e0;
    transform: translateY(-2px);
  }
`;

const PrettyTextArea = styled.textarea`
  width: calc(100% - 35px);
  padding: 15px;
  border: 2px solid #ddd;
  border-radius: 12px;
  font-size: 1rem;
  resize: vertical;
  min-height: 120px;
  margin-bottom: 30px;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: #23a6d5;
    box-shadow: 0 0 0 3px rgba(35, 166, 213, 0.1);
  }
`;

const Button = styled.button`
  padding: 15px 30px;
  font-size: 1.1rem;
  font-weight: bold;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PrettyButton = styled(Button)`
  background-color: #23a6d5;
  color: white;

  &:hover {
    background-color: #1c8ab1;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(35, 166, 213, 0.4);
  }
`;

const GrayButton = styled(Button)`
  background-color: #e0e0e0;
  color: #666;
  cursor: not-allowed;
`;

const AboutSection = styled.div`
  text-align: center;
  margin-bottom: 100px;
  animation: ${fadeIn} 0.8s ease-out 0.6s both;
`;

const AboutTitle = styled.h2`
  font-size: 3rem;
  margin-bottom: 30px;
  color: #333;
`;

const AboutText = styled.p`
  font-size: 1.2rem;
  color: #666;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.8;
`;

const AdditionalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 60px;
  animation: ${fadeIn} 0.8s ease-out 0.8s both;
`;

const AdditionalContentText = styled.p`
  font-size: 1.1rem;
  color: #666;
  font-weight: 600;
`;

const SocialIcon = styled.img`
  width: 40px;
  height: auto;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1) rotate(5deg);
  }
`;
