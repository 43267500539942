import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { ArrowRight } from "lucide-react";
import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/react";

export const LandingHead = ({ landingRef, handleImageClick, productReady }) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const fadeInUp = useSpring({
    opacity: visible ? 1 : 0,
    transform: visible ? "translateY(0px)" : "translateY(40px)",
    config: { mass: 1, tension: 280, friction: 60 },
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setVisible(entry.isIntersecting);
      },
      {
        threshold: 0.2,
        rootMargin: "0px 0px -100px 0px",
      }
    );

    if (landingRef.current) {
      observer.observe(landingRef.current);
    }

    return () => {
      if (landingRef.current) {
        observer.unobserve(landingRef.current);
      }
    };
  }, [landingRef]);

  const startChatFromKit = (input) => {
    const sanitizedPrompt = input.replace(/\s+/g, "-");
    const encodedPrompt = encodeURIComponent(sanitizedPrompt);
    navigate(`/starters/${encodedPrompt}`);
  };

  const goToStartersAll = () => {
    navigate("/starters");
  };

  return (
    <Container ref={landingRef}>
      <animated.div style={fadeInUp}>
        <Content>
          <HeaderContent>
            <LargeText>An AI Assistant for</LargeText>
            <GradientTextHeaderLarge>Home Improvement</GradientTextHeaderLarge>
            <Description>
              Get expert advice, step-by-step guides, and creative ideas for all
              your home projects.
            </Description>
          </HeaderContent>
          <StarterSection>
            <StarterHeader>Try it now</StarterHeader>
            <CardContainer>
              {starterPacks.map((pack, index) => (
                <Card
                  key={index}
                  onClick={() => startChatFromKit(pack.functionArgument)}
                >
                  <CardIcon>{pack.icon}</CardIcon>
                  <CardContent>
                    <CardTitle>{pack.title}</CardTitle>
                    <ArrowRight size={16} color="#f28500" />
                  </CardContent>
                </Card>
              ))}
            </CardContainer>
            <SeeAllButton onClick={goToStartersAll}>
              See all starters
            </SeeAllButton>
          </StarterSection>
          <AdditionalContent>
            <AdditionalContentText>As seen on</AdditionalContentText>
            <SocialIcon
              src="https://cdn-icons-png.flaticon.com/512/733/733579.png"
              alt="Twitter Logo"
            />
            <SocialIcon
              src="https://cdn-icons-png.flaticon.com/512/2111/2111589.png"
              alt="Reddit Logo"
            />
          </AdditionalContent>
        </Content>
      </animated.div>
    </Container>
  );
};

const starterPacks = [
  {
    title: "Home Improvement Genius",
    icon: "🔨",
    functionArgument: "home improvement genius",
  },
  { title: "Fix anything", icon: "🔧", functionArgument: "fix anything" },
  {
    title: "Identify a plant",
    icon: "🌿",
    functionArgument: "plant identifier",
  },
  {
    title: "Home Decorating Ideas",
    icon: "🏡",
    functionArgument: "home decorating ideas",
  },
];

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const Container = styled.div`
  width: 100%;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  gap: 4rem;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 800px;
`;

const LargeText = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const GradientTextHeaderLarge = styled.h1`
  font-size: 4rem;
  font-weight: 800;
  background: linear-gradient(45deg, #f28500, #ffcc70, #f28500);
  background-size: 200% 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 0 1rem;
  animation: ${gradientAnimation} 5s ease infinite;

  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;

const Description = styled.p`
  font-size: 1.25rem;
  color: #666;
  text-align: center;
  max-width: 600px;
  margin: 1rem auto 0;
  line-height: 1.6;
`;

const StarterSection = styled.section`
  width: 100%;
  background-color: #fff;
  padding: 3rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const StarterHeader = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  color: #f28500;
  text-align: center;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 2rem;
  width: calc(100% - 50px);

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(242, 133, 0, 0.15);
  }
`;

const CardIcon = styled.span`
  font-size: 3.5rem;
  margin-bottom: 1rem;
`;

const CardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const CardTitle = styled.h3`
  font-size: 1.2rem;
  text-align: left;
  font-weight: 600;
  color: #333;
  margin: 0;
`;

const SeeAllButton = styled.button`
  margin-top: 2rem;
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  color: #fff;
  background-color: #f28500;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e07a00;
  }
`;

const AdditionalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const AdditionalContentText = styled.p`
  font-size: 1.1rem;
  color: #666;
  font-weight: 500;
`;

const SocialIcon = styled.img`
  width: 28px;
  height: auto;
  opacity: 0.7;
  transition: opacity 0.3s ease, transform 0.3s ease;

  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }
`;

export default LandingHead;
