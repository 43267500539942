import styled from "@emotion/styled";
export const BrandDark = styled.span`
  color: black;
`;

export const BrandLight = styled.span`
  color: #7d7d7d; // A little darker gray but not too dark
`;

export const LogoContainer = styled.div`
  font-size: 24px; // Increased font size for visibility
  font-weight: bold; // Bold font weight to make it stand out
  cursor: pointer;
  &:hover {
    transform: scale(1.05); /* Grow slightly on hover */
    transition: transform 0.3s ease-in-out; /* Smooth transition */
  }
`;

export const MediumText = styled.p`
  font-size: 25px;
  color: #515151;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 5px;
`;

export const SmallText = styled.p`
  font-size: 18px;
  color: #515151;
  text-align: left;
  line-height: 1.5;
  margin-bottom: 5px;
`;

export const SmallButton = styled.p`
  font-size: 14px;
  color: black;
  font-weight: 500;
  text-align: left;
  line-height: 1.5;
  margin-bottom: 5px;
`;

export const VerySmallText = styled.p`
  font-size: 14px;
  color: #515151;
  text-align: left;
`;

export const SmallTextWidth = styled.p`
  font-size: 12px;
  color: #515151;
  line-height: 1.5;
  width: 200px;
  font-weight: 500;
`;

export const GradientTextHeader = styled.h1`
  background: linear-gradient(90deg, #aeaeae, #32325d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const LargeText = styled.h1`
  font-weight: 500;
  font-size: 70px;
  color: #333;
  text-align: center;
  margin: 2px 0;
  @media (max-width: 768px) {
    // Adjusts for screens smaller than 768px
    white-space: normal; // Allows text to wrap on smaller screens

    font-size: 70px; // Adjusts font size for mobile screens
  }
`;

export const AnotherInnerContainerIShouldGetRidOf = styled.div`
  padding: 15px;
  display: flex;
  max-width: 800px;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  border: ${(props) => (props.DEBUG ? "1px solid black" : "none")};
`;

export const BrandText = styled.h1`
  font-weight: 500;
  font-size: 20px;
  color: #333;
  text-align: center;
  margin: 2px 0;
  @media (max-width: 768px) {
    // Adjusts for screens smaller than 768px
    white-space: normal; // Allows text to wrap on smaller screens
  }
`;

export const GradientTextHeaderLarge = styled.h1`
  background: linear-gradient(120deg, black, #ffd1b1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  font-size: 70px;
  text-align: center;
  margin: 2px 0;
  @media (max-width: 768px) {
    font-size: 70px; // Adjusts font size for mobile screens
  }
`;

export const GradientTextHeaderMedium = styled.h1`
  background: linear-gradient(120deg, black, #ffd1b1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  font-size: 60px;
  text-align: center;
  margin: 2px 0;

  @media (max-width: 768px) {
    font-size: 70px; // Adjusts font size for mobile screens
  }
`;

export const GradientTextHeaderSmall = styled.h1`
  background: linear-gradient(120deg, black, #e5bc9f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  margin: 2px 0;
`;

export const PrettyButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  white-space: nowrap;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  background: linear-gradient(270deg, #f28500, #ffcc70, #f28500);
  background-size: 200% 200%; // Enlarging the background size for the gradient animation
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s ease-in-out;
  width: 120px;
  flex-shrink: 0;

  &:hover {
    transform: scale(1.05);
  }

  // Animation details
  animation: gradientShift 4s ease infinite;

  @keyframes gradientShift {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export const PrettyTextArea = styled.textarea`
  padding: 10px;
  font-size: 16px;
  color: #333;
  border: 2px solid #ccc;
  border-radius: 10px;
  width: 100%;
  max-width: 350px;
  max-height: 40px;
  resize: vertical;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
    outline: none;
  }
  @media (max-width: 768px) {
    // Adjusts for screens smaller than 768px
    max-width: 350px;
  }
`;

export const GrayButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  white-space: nowrap;
  color: white;

  border: none;
  border-radius: 10px;
  background: #7d7d7d;
`;

export const PrettyButtonStatic = styled.button`
  padding: 10px;
  font-size: 14px;
  white-space: nowrap;
  color: white;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  background: black;
  background-size: 200% 200%; // Enlarging the background size for the gradient animation
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

  &:hover {
    transform: scale(1.01);
  }
`;

export const PrettyButtonStaticNoBackground = styled.button`
  padding: 10px;
  font-size: 14px;
  white-space: nowrap;
  color: black;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  background: white;
  background-size: 200% 200%; // Enlarging the background size for the gradient animation

  &:hover {
    transform: scale(1.01);
  }
`;

export const AdditionalContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 40px;

  gap: 20px;
  border: ${(props) => (props.DEBUG ? "1px solid black" : "none")};

  @media (max-width: 768px) {
    // Use media query to adjust for smaller screens
    flex-direction: column; // Change flex direction to column on smaller screens
    margin-right: 0px;
    margin-top: 0px;
  }
`;

export const AdditionalContentText = styled.p`
  font-size: 16px;
  color: #515151;
  text-align: left;
  line-height: 1.5;
  margin: 0;
`;
