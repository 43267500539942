import React from "react";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  width: 80%;
  max-width: 600px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const ModalHeader = styled.h3`
  margin-top: 0;
  color: #333;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  color: #333;
  resize: none;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #0056b3;
  }
`;

const FeedbackModal = ({ setShowFeedback }) => {
  const { logout } = useAuth0();

  const handleBackdropClick = (event) => {
    if (event.target === event.currentTarget) {
      setShowFeedback(false);
    }
  };

  return (
    <ModalBackdrop onClick={handleBackdropClick}>
      <ModalContent>
        <ModalHeader>Feedback</ModalHeader>
        <TextAreaContainer>
          <TextArea placeholder="We appreciate your feedback. Please share your thoughts with us." />
        </TextAreaContainer>
        <Button onClick={() => logout()} style={{ width: "180px" }}>
          Send feedback
        </Button>
      </ModalContent>
    </ModalBackdrop>
  );
};

export default FeedbackModal;
