import React, { useRef, useState, useEffect } from "react";
import { useSpring, animated, config } from "react-spring";
import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/react";
import { Check, Clock, ArrowRight, Zap, Shield, Star } from "lucide-react";
import { createCheckOutSesssion } from "../functions/apiFunctions";
import { useAuth0 } from "@auth0/auth0-react";

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const Container = styled.section`
  padding: 100px 0;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 15s ease infinite;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;

const PricingHeader = styled.h1`
  color: #ffffff;
  font-size: 3.5rem;
  text-align: center;
  margin-bottom: 2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  font-weight: 800;
  letter-spacing: -1px;
`;

const CardContainer = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1200px;
  width: 100%;
  padding: 0 2rem;
`;

const PricingCard = styled(animated.div)`
  background-color: rgba(255, 255, 255, 0.95);
  padding: 2.5rem;
  width: 100%;
  max-width: 380px;
  border-radius: 30px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-10px) scale(1.02);
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.2);
  }

  &::before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 80%
    );
    transform: rotate(45deg);
    pointer-events: none;
  }
`;

const PlanTitle = styled.h2`
  color: #333;
  font-size: 2.2rem;
  margin-bottom: 1rem;
  border-bottom: 3px solid #23a6d5;
  padding-bottom: 0.5rem;
  font-weight: 700;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 2rem 0;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin: 1rem 0;
  font-size: 1.1rem;
  color: #555;
  transition: transform 0.2s ease;
  gap: 5px;

  &:hover {
    transform: translateX(5px);
  }
`;

const iconStyles = css`
  margin-right: 0.75rem;
  flex-shrink: 0;
`;

const ActionButton = styled.button`
  width: 100%;
  padding: 1.2rem;
  font-size: 1.1rem;
  color: white;
  background: linear-gradient(135deg, #23a6d5 0%, #23d5ab 100%);
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(35, 166, 213, 0.4);
  }

  &:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(35, 166, 213, 0.4);
  }
`;

const SmallText = styled.p`
  font-size: 1rem;
  color: #777;
  text-align: center;
  margin-top: 1.5rem;
`;

const Badge = styled.span`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: linear-gradient(135deg, #ff6b6b 0%, #feca57 100%);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
`;

export const Pricing = ({}) => {
  const pricingRef = useRef(null); // Added missing ref
  const { getAccessTokenSilently } = useAuth0();
  const [visible, setVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const springProps = useSpring({
    opacity: visible ? 1 : 0,
    transform: visible ? "translateY(0)" : "translateY(40px)",
    config: config.gentle,
  });

  const buttonProps = useSpring({
    scale: isHovered ? 1.05 : 1,
    config: config.wobbly,
  });

  const createCheckout = async () => {
    const token = await getAccessTokenSilently();
    const response = await createCheckOutSesssion(token);
    // Handle the response here
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (pricingRef.current) {
      observer.observe(pricingRef.current);
    }

    return () => {
      if (pricingRef.current) {
        observer.unobserve(pricingRef.current);
      }
    };
  }, [pricingRef]);

  return (
    <Container ref={pricingRef} id="pricing">
      <PricingHeader>Unlock Your Potential</PricingHeader>
      <CardContainer>
        <PricingCard style={springProps}>
          <Badge>New</Badge>
          <PlanTitle>Beginner</PlanTitle>
          <FeatureList>
            <FeatureItem>
              <Zap css={iconStyles} size={20} color="#23a6d5" />
              400 messages a month
            </FeatureItem>
            <FeatureItem>
              <Star css={iconStyles} size={20} color="#23a6d5" />
              Advanced image understanding AI
            </FeatureItem>
            <FeatureItem>
              <Shield css={iconStyles} size={20} color="#23a6d5" />
              Cutting-edge AI technology
            </FeatureItem>
            <FeatureItem>
              <Check css={iconStyles} size={20} color="#23a6d5" />
              Mobile Web App
            </FeatureItem>
            <FeatureItem>
              <Check css={iconStyles} size={20} color="#23a6d5" />
              Access to all Build Startkits
            </FeatureItem>
            <FeatureItem>
              <Check css={iconStyles} size={20} color="#23a6d5" />
              To-Do Manager
            </FeatureItem>
            <FeatureItem>
              <Check css={iconStyles} size={20} color="#23a6d5" />
              Message history preservation
            </FeatureItem>
            <FeatureItem>
              <Clock css={iconStyles} size={20} color="#feca57" />
              Smart Shopping List (Coming Soon)
            </FeatureItem>
          </FeatureList>
          <animated.div style={buttonProps}>
            <ActionButton
              onClick={createCheckout}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              Get Started Now{" "}
              <ArrowRight size={20} style={{ marginLeft: "0.75rem" }} />
            </ActionButton>
          </animated.div>
          <SmallText>Only $10/month - 30-day money-back guarantee</SmallText>
        </PricingCard>
        {/* <PricingCard style={springProps}>
          <Badge>Coming Soon</Badge>
          <PlanTitle>Add Credits</PlanTitle>
          <FeatureList>
            <FeatureItem>
              <Zap css={iconStyles} size={20} color="#23a6d5" />
              Pay as you go
            </FeatureItem>
            <FeatureItem>
              <Star css={iconStyles} size={20} color="#23a6d5" />
              Flexible credit system
            </FeatureItem>
            <FeatureItem>
              <Shield css={iconStyles} size={20} color="#23a6d5" />
              No monthly commitment
            </FeatureItem>
            <FeatureItem>
              <Check css={iconStyles} size={20} color="#23a6d5" />
              Use credits for any feature
            </FeatureItem>
            <FeatureItem>
              <Clock css={iconStyles} size={20} color="#feca57" />
              Smart Shopping List (Coming Soon)
            </FeatureItem>
          </FeatureList>
          <animated.div style={buttonProps}>
            <ActionButton
              onClick={() => alert("Coming Soon!")}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              Learn More{" "}
              <ArrowRight size={20} style={{ marginLeft: "0.75rem" }} />
            </ActionButton>
          </animated.div>
          <SmallText>Flexible pricing - Coming Soon</SmallText>
        </PricingCard> */}
      </CardContainer>
    </Container>
  );
};
