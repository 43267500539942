import ReactMarkdown from "react-markdown";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Send,
  Image as ImageIcon,
  ChevronLeft,
  ChevronRight,
  ArrowRight,
} from "lucide-react";

import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

import styled from "@emotion/styled";

import { useState, useRef, useEffect } from "react";

// import Box from "@mui/material/Box";

import { Helmet } from "react-helmet-async";

import { DEBUG } from "../../functions/debug";

import { motion } from "framer-motion";

export const ChatUI = ({
  messages,
  isPanelOpen,
  togglePanel,
  isLoading,
  inputValue,
  handleSendMessage,
  image,
  isAuthenticated,
  setImage,
  setInputValue,
}) => {
  const navigate = useNavigate(); // Use useNavigate hook to get the navigate function
  const maxLength = 4000;
  const [dragging, setDragging] = useState(false);

  const handleInputChange = (event) => {
    if (event.target.value.length <= maxLength) {
      setInputValue(event.target.value);
    }
  };

  const bottomChatRef = useRef(null);
  useEffect(() => {
    bottomChatRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const startChatFromKit = (input) => {
    console.log("input", input);
    const sanitizedPrompt = input.replace(/\s+/g, "-");
    const encodedPrompt = encodeURIComponent(sanitizedPrompt);
    navigate(`/starters/${encodedPrompt}`);
  };

  const goToStarters = () => {
    navigate("/starters");
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);

    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const file = event.dataTransfer.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <MainContainer isPanelOpen={isPanelOpen} DEBUG={DEBUG}>
      {isAuthenticated && (
        <PanelIconContainer onClick={togglePanel}>
          {isPanelOpen ? <ChevronLeft size={25} /> : <ChevronRight size={25} />}
        </PanelIconContainer>
      )}
      <>
        {messages.length === 0 ? (
          <ChatPlaceholderContainer>
            <StarterSection>
              <StarterHeader onClick={goToStarters}>Try it</StarterHeader>
              <CardContainer>
                {starterPacks.map((pack, index) => (
                  <Card
                    key={index}
                    onClick={() => startChatFromKit(pack.functionArgument)}
                  >
                    <CardIcon>{pack.icon}</CardIcon>
                    <CardContent>
                      <CardTitle>{pack.title}</CardTitle>
                      <ArrowRight size={16} color="#f28500" />
                    </CardContent>
                  </Card>
                ))}
              </CardContainer>
              <CardTitle style={{ margin: 20 }}>Ask anything below</CardTitle>
            </StarterSection>
          </ChatPlaceholderContainer>
        ) : (
          <ChatContainer DEBUG={DEBUG}>
            <MessagesContainer DEBUG={DEBUG}>
              {messages.map((message, index) => (
                <MessageContainer key={index} role={message.role} DEBUG={DEBUG}>
                  <MessageText role={message.role}>
                    {message.role === "user" ? (
                      // Handle the case where message.content is an array
                      message.content.map((contentItem, idx) => (
                        <div key={idx}>
                          {contentItem.type === "image_url" && (
                            <img
                              src={contentItem.image_url.url}
                              alt="User uploaded"
                              style={{
                                maxWidth: "200px",
                                height: "auto",
                                marginBottom: "10px",
                                borderRadius: "10px",
                              }}
                            />
                          )}
                          {contentItem.type === "text" && (
                            <ReactMarkdown>{contentItem.text}</ReactMarkdown>
                          )}
                        </div>
                      ))
                    ) : (
                      // Handle the case where message.content is a string (for the assistant role)
                      <ReactMarkdown>{message.content}</ReactMarkdown>
                    )}
                  </MessageText>
                </MessageContainer>
              ))}
              {isLoading && <LoadingAnimation />}
              <div ref={bottomChatRef} />
            </MessagesContainer>
          </ChatContainer>
        )}
      </>
      <BottomContainer DEBUG={DEBUG}>
        <InputContainer
          onDragEnter={handleDragEnter}
          onDragOver={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          dragging={dragging}
        >
          <StyledTextField
            fullWidth
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Type your message here"
            multiline
            maxRows={20}
            inputProps={{
              maxLength: 5000, // Explicitly high limit
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton component="label">
                    <ImageIcon />
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </IconButton>
                  <IconButton onClick={() => handleSendMessage()} edge="end">
                    <Send />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter" && event.metaKey) {
                handleSendMessage();
                event.preventDefault();
              }
            }}
          />
          {image && <PreviewImage src={image} alt="Preview" />}
        </InputContainer>
      </BottomContainer>
    </MainContainer>
  );
};

const starterPacks = [
  {
    title: "Identify a plant",
    icon: "🌿",
    functionArgument: "plant identifier",
  },
  { title: "Fix anything", icon: "🔧", functionArgument: "fix anything" },
];

const PanelIconContainer = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: ${(props) => (props.DEBUG ? "1px solid purple" : "none")};
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f9f9f9;

  z-index: 100;

  border: ${(props) => (props.DEBUG ? "1px solid green" : "none")};
  border-top: 1px solid #ccc;
`;

// // Responsive Input Container
const InputContainer = styled.div`
  width: 100%; // Full width to be responsive
  max-width: 800px; // Maximum width
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

// const PreviewImage = styled.img`
//   width: 100px;
//   height: auto;
//   margin-top: 10px;
// `;

// // Styled TextField for custom styling
// const StyledTextField = styled(TextField)`
//   && {
//     .MuiInputBase-input {
//       color: #333; // Set text color
//     }
//     .MuiOutlinedInput-root {
//       & fieldset {
//         border-color: #515151; // Set border color
//       }
//       &:hover fieldset {
//         border-color: #999; // Set border color on hover
//       }
//       &.Mui-focused fieldset {
//         border-color: #555; // Set border color when focused
//       }
//     }
//   }
// `;

const InputWrapper = styled.div`
  width: 100%;
  padding: 1rem;
  background-color: #ffffff;
  border-top: 1px solid #e9ecef;
`;

const StyledTextField = styled(TextField)`
  margin: 0 100px;

  .MuiOutlinedInput-root {
    background-color: white;
    border-radius: 1rem;

    &.Mui-focused fieldset {
      border-color: #007bff;
    }
  }
`;

const PreviewImage = styled.img`
  width: 100px;
  height: auto;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
`;

const LoadingAnimation = () => (
  <motion.div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100px",
    }}
  >
    {[0, 1, 2].map((index) => (
      <motion.div
        key={index}
        style={{
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          backgroundColor: "#3B82F6", // Bright blue
          margin: "0 6px",
        }}
        animate={{
          y: ["0%", "-100%", "0%"],
          opacity: [0.2, 1, 0.2],
        }}
        transition={{
          duration: 1.2,
          repeat: Infinity,
          ease: "easeInOut",
          delay: index * 0.2,
        }}
      />
    ))}
  </motion.div>
);

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 1.5rem;
  width: 100%;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(242, 133, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(242, 133, 0, 0.15);
  }
`;

const CardIcon = styled.span`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const CardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const CardTitle = styled.h3`
  font-size: 1.1rem;
  text-align: left;
  font-weight: 600;
  color: #333;
  margin: 0;
`;

const StarterSection = styled.section`
  width: 500px;
  background-color: #fff5e6;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(242, 133, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    padding: 1em;
    width: 80%;
  }
`;

const StarterHeader = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  color: #f28500;
  text-align: center;
  margin-bottom: 2rem;
  cursor: pointer;
  transition: transform 0.3s ease; /* Add transition for smooth effect */

  &:hover {
    transform: scale(1.05); /* Grow on hover */
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;

const MainContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  border: ${(props) => (props.DEBUG ? "1px solid purple" : "none")};
  @media (max-width: 768px) {
    /* Adjust breakpoint as needed */
    display: ${(props) => (props.isPanelOpen ? "none" : "flex")};
    transition: display 0.3s ease; /* Smooth transition for display */
  }
`;

const ChatContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  border: ${(props) => (props.DEBUG ? "1px solid red" : "none")};
`;
const ChatPlaceholderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
`;

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0; /* This forces the container to respect the flex layout */
  border: ${(props) => (props.DEBUG ? "1px solid blue" : "none")};

  @media (max-width: 768px) {
    padding: 10px;
    margin-bottom: 60px; /* Adjust based on your input bar height */
  }
`;

// Styled Components
const MessageContainer = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.role === "user" ? "flex-end" : "flex-start"};
  padding: 8px;
  margin: 4px;
  width: 100%;

  border: ${(props) => (props.DEBUG ? "1px solid black" : "none")};
  @media (max-width: 768px) {
    /* Adjust for smaller screens */
    padding: 0;
    margin: 0;
    width: 100%;
  }
`;

const MessageText = styled.div`
  background-color: ${(props) =>
    props.role === "user" ? "#ffe8d8" : "#FFFFFF"};
  padding: 10px 20px;
  border-radius: 20px;
  max-width: 80%;
  border: 1px solid ${(props) => (props.role === "user" ? "#DCF8C6" : "None")};
  box-shadow: ${(props) =>
    props.role === "user" ? "0px 1px 3px rgba(0, 0, 0, 0.1)" : "None"};
  text-align: left;
  max-width: 700px;
`;
const MiniText = styled.div`
  font-size: 12px;
  color: #000000;
  margin: 5px;
`;
