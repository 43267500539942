import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter, useNavigate } from "react-router-dom";

const Auth0ProviderWithHistory = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    const starterLandingPage = localStorage.getItem("starterLandingPage");
    if (starterLandingPage) {
      localStorage.removeItem("starterLandingPage");
      // Parse the URL to get only the path
      const url = new URL(starterLandingPage);
      navigate(url.pathname + url.search + url.hash);
    } else {
      navigate(appState?.returnTo || window.location.pathname);
    }
  };

  return (
    <Auth0Provider
      domain="dev-1n1x947z.auth0.com"
      clientId="iJyGPHtT0n2746kESE63cpnO5tN1rXHJ"
      authorizationParams={{
        audience: "https://www.dooe.io",
        redirectUri: window.location.origin,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <App />
    </Auth0ProviderWithHistory>
  </BrowserRouter>
);

reportWebVitals();
