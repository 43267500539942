import React from "react";
import styled from "styled-components";
import { PrettyButton, PrettyButtonStatic } from "../styles/styledComponents";
import {
  cancelSubscription,
  createCheckOutSesssion,
} from "../functions/apiFunctions";
import { useAuth0 } from "@auth0/auth0-react";

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  width: 80%;
  max-width: 500px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const ModalHeader = styled.h3`
  margin-top: 0;
  color: #333;
`;

const ModalBody = styled.p`
  color: #666;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #0056b3;
  }
`;

const SettingsModal = ({ hasSubscription, setShowSettings }) => {
  const { getAccessTokenSilently, logout } = useAuth0();

  const cancelSubscriptionClicked = async () => {
    const token = await getAccessTokenSilently();

    await cancelSubscription(token);
  };

  const handleBackdropClick = (event) => {
    if (event.target === event.currentTarget) {
      setShowSettings(false);
    }
  };

  return (
    <ModalBackdrop onClick={handleBackdropClick}>
      <ModalContent
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 5,
        }}
      >
        <ModalHeader>Settings </ModalHeader>
        <ModalBody>
          For support, please contact{" "}
          <a href="mailto:dooehelp@gmail.com">DooeHelp@gmail.com</a>{" "}
        </ModalBody>

        <PrettyButtonStatic onClick={() => logout()} style={{ width: "180px" }}>
          Log Out
        </PrettyButtonStatic>
        {hasSubscription ? (
          <>
            <ModalBody>
              If you no longer need your subscription, click below to cancel it.
            </ModalBody>
            <PrettyButtonStatic
              onClick={() => cancelSubscriptionClicked()}
              style={{ width: "180px", backgroundColor: "red" }}
            >
              Cancel Subscription
            </PrettyButtonStatic>
          </>
        ) : (
          <div style={{ height: "100px" }}></div>
        )}
      </ModalContent>
    </ModalBackdrop>
  );
};

export default SettingsModal;
