import React, { useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ToDo } from "./ToDoSection";
import { useAuth0 } from "@auth0/auth0-react";
import { DEBUG } from "../../functions/debug";
import { getMessagesFromSession } from "../../functions/apiFunctions";
import { useNavigate } from "react-router-dom";

import {
  ArrowLeft,
  ChevronLeft,
  Settings,
  Home,
  MessageSquare,
  Plus,
  ChevronDown,
  Package,
} from "lucide-react";
import { Tooltip } from "react-tooltip";

export const SidePanel = ({
  isOpen,
  togglePanel,
  sessions,
  setMessages,
  setSessionId,
  setShowSettings,
  setShowFeedback,
  abTests,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const isMobile = window.innerWidth < 768;
  const [isCollapsed, setIsCollapsed] = useState(false);

  const returnToHome = () => {
    console.log("Returning to home");
    navigate("/");
  };
  const goToStarters = () => {
    console.log("Returning to home");
    navigate("/starters");
  };

  const loadMessages = async (sessionId) => {
    const token = await getAccessTokenSilently();
    const response = await getMessagesFromSession(token, sessionId);
    console.log("response", response);
    const newMessages = response?.messages;
    if (newMessages) {
      if (DEBUG) console.log("New messages", newMessages);
      setMessages(newMessages);
      setSessionId(sessionId);
    }
    if (isMobile) {
      togglePanel();
    }
  };

  const openSettings = () => {
    setShowSettings(true);
  };

  const openFeedback = () => {
    console.log("Opening feedback modal");
    setShowFeedback(true);
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const startNewChat = () => {
    console.log("Starting new chat");
    setMessages([]);
    setSessionId(null);
  };

  return (
    <Container isOpen={isOpen}>
      <Header>
        <SectionTitle>Messages</SectionTitle>
        {isMobile && (
          <IconButton onClick={togglePanel} aria-label="Close panel">
            <ChevronLeft size={25} />
          </IconButton>
        )}
      </Header>
      <Content>
        <HistorySection>
          <NewChatButton onClick={() => startNewChat()}>
            <Plus size={16} />
            New Chat
          </NewChatButton>
          <HistoryHeader onClick={toggleCollapse}>
            <SectionSubtitle>Recent Conversations</SectionSubtitle>
            <ChevronDown
              size={20}
              style={{
                transform: isCollapsed ? "rotate(-180deg)" : "rotate(0deg)",
                transition: "transform 0.3s ease",
              }}
            />
          </HistoryHeader>
          {!isCollapsed && (
            <>
              {sessions && sessions.length > 0 ? (
                <SessionList>
                  {sessions.map((session, index) => (
                    <SessionItem
                      key={index}
                      onClick={() => loadMessages(session.id)}
                    >
                      <MessageSquare size={16} />
                      <SessionTitle>
                        {session?.title || "Untitled Conversation"}
                      </SessionTitle>
                    </SessionItem>
                  ))}
                </SessionList>
              ) : (
                <HistoryPlaceholder>No chat history</HistoryPlaceholder>
              )}
            </>
          )}
        </HistorySection>
      </Content>
      <StickySection>
        <ToDo abTests={abTests} />
        <FooterSection>
          <IconButton
            onClick={returnToHome}
            aria-label="Go to home"
            data-tooltip-id="home-tooltip"
            data-tooltip-content="Go to Home"
          >
            <Home size={20} />
          </IconButton>
          <IconButton
            onClick={goToStarters}
            aria-label="Go to Starter Packs"
            data-tooltip-id="starters-tooltip"
            data-tooltip-content="Go to Starter Packs"
          >
            <Package size={20} />
          </IconButton>
          <IconButton
            onClick={openSettings}
            aria-label="Open settings"
            data-tooltip-id="settings-tooltip"
            data-tooltip-content="Settings"
          >
            <Settings size={20} />
          </IconButton>
        </FooterSection>
        <Tooltip id="home-tooltip" />
        <Tooltip id="starters-tooltip" />
        <Tooltip id="settings-tooltip" />
      </StickySection>
    </Container>
  );
};

const Container = styled.aside`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 300px;
  background-color: #f8f9fa;
  border-right: 1px solid #e9ecef;
  overflow: hidden;
  z-index: 1000;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  transform: translateX(${(props) => (props.isOpen ? "0" : "-100%")});

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #e9ecef;
`;

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
`;

const HistorySection = styled.section`
  margin-bottom: 20px;
`;

const HistoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  user-select: none;
`;

const SectionTitle = styled.h2`
  font-size: 1.4rem;
  font-weight: 600;
  color: #333;
  margin: 0;
`;

const SectionSubtitle = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  color: #555;
  margin: 0;
`;

const SessionList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const SessionItem = styled.li`
  display: flex;
  align-items: center;
  padding: 12px;
  margin-bottom: 8px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;

  &:hover {
    background-color: #f0f0f0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const SessionTitle = styled.span`
  margin-left: 10px;
  font-size: 0.9rem;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const HistoryPlaceholder = styled.div`
  padding: 20px;
  text-align: center;
  color: #6c757d;
  font-style: italic;
`;

const NewChatButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-bottom: 15px;

  &:hover {
    background-color: #0056b3;
  }

  svg {
    margin-right: 8px;
  }
`;

const StickySection = styled.div`
  border-top: 1px solid #e9ecef;
  padding: 20px;
`;

const FooterSection = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 20px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: black;
  transition: color 0.2s;
  padding: 8px;
  border-radius: 50%;

  &:hover {
    color: #343a40;
    background-color: #e9ecef;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #007bff;
  }
`;

export default SidePanel;
