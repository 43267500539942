import { createContext, useContext, useState } from "react";

// Create the context
const GeneralContext = createContext();

// Create a provider component
export const GeneralProvider = ({ children }) => {
  const [passedImage, setPassedImage] = useState(null);
  const [passedPrompt, setPassedPrompt] = useState(null);

  // Example function to change state

  return (
    <GeneralContext.Provider
      value={{
        passedImage,
        setPassedImage,
        passedPrompt,
        setPassedPrompt,
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};

// Custom hook for using this context
export const useGeneral = () => useContext(GeneralContext);
