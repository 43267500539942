import { LandingHead } from "./LandingHead";

import styled from "@emotion/styled";
import { StartersExplorer } from "./StartersExplorer";
import { useEffect, useRef, useState } from "react";
import HowItWorks from "./HowItWorks";
import { Pricing } from "../../Pricing";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";
import { NavigationStatic } from "../../NavigationStatic";

const DEBUG = false;
export const LandingVersion2 = () => {
  const starterKitRef = useRef(null);
  const landingRef = useRef(null);
  const howItWorksRef = useRef(null); // Added missing ref

  const productReady = true;

  // useEffect(() => {
  //   const starterLandingPage = localStorage.getItem("starterLandingPage");
  //   if (starterLandingPage) {
  //     console.log("Starter Landing Page URL:", starterLandingPage);
  //     localStorage.removeItem("starterLandingPage");
  //     window.location.href = starterLandingPage;
  //   }
  // }, []);

  const handleImageClick = () => {
    console.log("Image was clicked!");
    // You can implement any response here, such as opening a modal or redirecting to another page
    scrollToRef(starterKitRef);
  };

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Container>
      <NavigationStatic />
      <LandingHead
        landingRef={landingRef}
        handleImageClick={handleImageClick}
        productReady={productReady}
      />
      <HowItWorks howItWorksRef={howItWorksRef} />
      <Pricing productReady={productReady} />
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  background-color: white;
  width: 100%;
  overflow-x: hidden; // Hide horizontal overflow
`;
