import React from "react";
import { Link } from "react-router-dom";
import { customTaskLookup } from "../static/customStarters";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { ArrowRight, Sparkles } from "lucide-react";
import { NavigationStatic } from "./NavigationStatic";

const StartersAll = () => {
  return (
    <PageWrapper>
      <NavigationStatic />
      <Container>
        <Content>
          <HeaderContent>
            <GradientTextHeaderLarge>
              Explore Starters <Sparkles size={32} />
            </GradientTextHeaderLarge>
            <Description>
              Discover a variety of AI-powered starters to kickstart your
              projects and boost your productivity.
            </Description>
          </HeaderContent>
          <StarterSection>
            <CardContainer>
              {Object.entries(customTaskLookup)
                .filter(([_, taskDetails]) => !taskDetails.hideFromStartersAll)
                .map(([taskName, taskDetails], index) => (
                  <Card
                    key={taskName}
                    to={`/starters/${taskName.replace(/ /g, "-")}`}
                    style={{ animationDelay: `${index * 0.1}s` }}
                  >
                    <CardIcon>{taskDetails.icon || "🚀"}</CardIcon>
                    <CardContent>
                      <CardTitle>{taskName}</CardTitle>
                      <CardDescription>
                        {taskDetails.preAuthText}
                      </CardDescription>
                    </CardContent>
                    <ExploreLink>
                      Explore <ArrowRight size={16} />
                    </ExploreLink>
                    <ShimmerEffect />
                  </Card>
                ))}
            </CardContainer>
          </StarterSection>
        </Content>
      </Container>
    </PageWrapper>
  );
};

export default StartersAll;

// Animations
const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

// Styled Components
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
`;

const Container = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;
  overflow-y: auto;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 1200px;
  gap: 60px;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 800px;
  animation: ${fadeInUp} 0.6s ease-out;
`;

const GradientTextHeaderLarge = styled.h1`
  font-size: 4rem;
  font-weight: 800;
  background: linear-gradient(45deg, #3a86ff, #ff006e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 0 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Description = styled.p`
  font-size: 1.25rem;
  color: #4a5568;
  text-align: center;
  max-width: 600px;
  margin: 1rem auto 0;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const StarterSection = styled.section`
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  width: calc(100% - 50px);
`;

const Card = styled(Link)`
  position: relative;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-decoration: none;
  color: inherit;
  transition: all 0.3s ease;
  animation: ${fadeInUp} 0.6s ease-out backwards;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px) scale(1.02);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, #3a86ff, #ff006e);
    border-radius: 16px 16px 0 0;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::before {
    opacity: 1;
  }
`;

const CardIcon = styled.span`
  font-size: 3rem;
  margin-bottom: 1rem;
  display: inline-block;
  padding: 0.5rem;
  background-color: #f0f4f8;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const CardTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  color: #2d3748;
  margin: 0 0 0.5rem;
  position: relative;
  display: inline-block;

  &::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #3a86ff, #ff006e);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
  }
`;

const CardDescription = styled.p`
  font-size: 1rem;
  color: #4a5568;
  margin: 0 0 1rem;
  line-height: 1.6;
`;

const ExploreLink = styled.span`
  display: flex;
  align-items: center;
  color: #3a86ff;
  font-weight: 600;
  margin-top: auto;
  transition: all 0.3s ease;

  svg {
    margin-left: 0.5rem;
    transition: transform 0.3s ease;
  }
`;

const ShimmerEffect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite;
  pointer-events: none;
`;
