import styled from "@emotion/styled";
import { Check as CheckIcon } from "@mui/icons-material";

import { useEffect, useState } from "react";
import {
  LargeText,
  MediumText,
  GradientTextHeaderLarge,
  PrettyButton,
  AnotherInnerContainerIShouldGetRidOf,
  AdditionalContent,
  AdditionalContentText,
} from "../../../styles/styledComponents";
import { useNavigate } from "react-router-dom";
import { useGeneral } from "../../../contexts/GeneralContext"; // Ensure the path is correct

export const LandingHead = ({ landingRef, handleImageClick, productReady }) => {
  const DEBUG = false;
  let typingPlaceholder = "What are you working on?";
  if (!productReady) {
    typingPlaceholder = "Enter email to get notified";
  }

  const navigate = useNavigate(); // Initialize the useNavigate hook

  const [inputValue, setInputValue] = useState("");

  const [placeholder, setPlaceholder] = useState("");

  const getStarted = () => {
    console.log("inputValue", inputValue);
    let input = inputValue;
    if (input) {
      const sanitizedPrompt = input.replace(/\s+/g, "-"); // Replace spaces with `-`
      const encodedPrompt = encodeURIComponent(sanitizedPrompt);
      navigate(`/chat?starter=${encodedPrompt}`); // Navigate to the Chat page with the prompt as a query parameter
    } else {
      let input = "Hello!";
      const sanitizedPrompt = input.replace(/\s+/g, "-"); // Replace spaces with `-`
      const encodedPrompt = encodeURIComponent(sanitizedPrompt);
      navigate(`/chat?starter=${encodedPrompt}`); // Navigate to the Chat page with the prompt as a query parameter
    }
  };

  useEffect(() => {
    let charIndex = 0;
    const timer = setInterval(() => {
      if (charIndex < typingPlaceholder.length) {
        setPlaceholder(typingPlaceholder.slice(0, charIndex + 1));
        charIndex++;
      } else {
        clearInterval(timer);
      }
    }, 50); // Adjust the speed of typing here

    return () => clearInterval(timer);
  }, [typingPlaceholder]);

  return (
    <Container ref={landingRef} DEBUG={DEBUG}>
      <Content DEBUG={DEBUG}>
        <AnotherInnerContainerIShouldGetRidOf DEBUG={DEBUG}>
          <div style={{ margin: "30px" }}>
            <>
              <LargeText>Your assistant for home improvement in </LargeText>
              <GradientTextHeaderLarge>less time</GradientTextHeaderLarge>
              <MediumText>
                Be it - DIY, home improvement, or cooking, or fixing a toilet -
                the assistant can provide expert input when you need it.
              </MediumText>
            </>
          </div>

          <InputContainer DEBUG={DEBUG}>
            <InputField
              placeholder={placeholder}
              maxLength={50}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <PrettyButton onClick={() => getStarted()}>
              Get Started
            </PrettyButton>
          </InputContainer>
        </AnotherInnerContainerIShouldGetRidOf>
      </Content>
    </Container>
  );
};

// Styled Components
const Container = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0;
  border: ${(props) => (props.DEBUG ? "1px solid blue" : "none")};
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 0;

  gap: 20px;
  border: ${(props) => (props.DEBUG ? "1px solid red" : "none")};

  @media (max-width: 768px) {
    // Use media query to adjust for smaller screens
    flex-direction: column; // Change flex direction to column on smaller screens
  }
`;

const InputField = styled.input`
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 10px;
  max-width: 250px;
  border: none;
  outline: none;
  width: calc(100% - 130px); // Adjust width dynamically
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background: white; // Neutral background
  color: #333;
  flex-grow: 1; // Allows the input field to grow and fill available space
  min-width: 200px; // Ensures it doesn't get too small on narrow screens
  border: ${(props) => (props.DEBUG ? "1px solid black" : "none")};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%; // Ensure the container takes the full width
  border: ${(props) => (props.DEBUG ? "1px solid black" : "none")};
`;

const FeatureList = styled.ul`
  list-style: none; // Removes default list styling
  padding: 0;
  margin: 0;
  margin-bottom: 15px;
`;
const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin: 8px 0;
`;
const FeatureText = styled.span`
  margin-left: 10px; // Spacing between icon and text
  font-size: 18px;
  color: #515151;
`;
