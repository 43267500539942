// App.js
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import styled from "@emotion/styled";
import "./App.css";
import { Landing } from "./pages/Landing";
import AuthenticatedChat from "./components/chat/AuthenticatedChat";
import { GeneralProvider } from "./contexts/GeneralContext";
import { useEffect, useState } from "react";
import { Starters } from "./static/Starters";
import { HelmetProvider } from "react-helmet-async";
import { useAuth0 } from "@auth0/auth0-react";
import { assignABTest } from "./functions/helpers";
import Chat from "./pages/Chat";
import StartersAll from "./components/StartersAll";
import { PricingPage } from "./components/PricingPage";

const DEBUG = false;
function App() {
  if (DEBUG) console.log("[app]");
  const [abTests, setAbTests] = useState([{ test_result: 2 }]); // Initialize the abTests state with an empty array
  const { user, isAuthenticated, isLoading } = useAuth0();

  // useEffect(() => {
  //   // Check if the abTests array exists in local storage
  //   let storedAbTests = localStorage.getItem("abTests");
  //   if (DEBUG) console.log("storedAbTests", storedAbTests);

  //   if (!storedAbTests) {
  //     if (DEBUG) console.log("storedAbTests doesn't exist");
  //     storedAbTests = assignABTest();
  //     setAbTests(storedAbTests);
  //     localStorage.setItem("abTests", JSON.stringify(storedAbTests));
  //     if (DEBUG) console.log("New abTests stored", storedAbTests);
  //   } else {
  //     if (DEBUG) console.log("storedAbTests exists");
  //     // If abTests exists, parse the JSON string to an array
  //     storedAbTests = JSON.parse(storedAbTests);

  //     if (DEBUG) {
  //       let debugTest = [
  //         {
  //           test_result: 2,
  //         },
  //       ];
  //       console.log("Using debugTest ==> ", debugTest);
  //       setAbTests(debugTest);
  //     } else {
  //       setAbTests(storedAbTests);
  //     }

  //     if (DEBUG) console.log("abTests set", storedAbTests);
  //   }
  // }, []);

  // TODO: HAVE THIS FIRE ON NAVIGATE BECAUSE GOING TO /CHAT BREAKS MOBILE

  useEffect(() => {
    const setVH = () => {
      console.log("[app] ====> RESIZE");
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    setVH(); // Set the value on initial load
    window.addEventListener("resize", setVH); // Update it on resize

    return () => {
      window.removeEventListener("resize", setVH);
    };
  }, []);

  return (
    <StyledApp className="App">
      <GeneralProvider>
        <HelmetProvider>
          <Routes>
            <Route path="/" element={<Landing abTests={abTests} />} />
            <Route path="/chat" element={<Chat abTests={abTests} />} />
            <Route path="/starters" element={<StartersAll />} />{" "}
            {/* New route */}
            <Route path="/starters/:task" element={<Starters />} />
            <Route path="/pricing" element={<PricingPage />} />
          </Routes>
        </HelmetProvider>
      </GeneralProvider>
    </StyledApp>
  );
}

export default App;

const StyledApp = styled.div`
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
