import React, { useRef, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useSpring, animated } from "react-spring";
import { LargeText } from "../../../styles/styledComponents";

const HowItWorks = ({ howItWorksRef }) => {
  const [visible, setVisible] = useState(false);

  const fadeInUp = useSpring({
    opacity: visible ? 1 : 0,
    transform: visible ? "translateY(0px)" : "translateY(40px)",
    config: { mass: 1, tension: 280, friction: 60 },
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setVisible(entry.isIntersecting);
      },
      {
        threshold: 0.2,
        rootMargin: "0px 0px -100px 0px",
      }
    );

    if (howItWorksRef.current) {
      observer.observe(howItWorksRef.current);
    }

    return () => {
      if (howItWorksRef.current) {
        observer.unobserve(howItWorksRef.current);
      }
    };
  }, [howItWorksRef]);

  return (
    <Container ref={howItWorksRef}>
      <animated.div style={fadeInUp}>
        <LargeText>How it works</LargeText>
        <Subtitle>Start your project today</Subtitle>
        <StepsContainer>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepNumber>{index + 1}</StepNumber>
              <StepContent>
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </StepContent>
            </Step>
          ))}
        </StepsContainer>
      </animated.div>
    </Container>
  );
};

const steps = [
  {
    title: "Describe what you're working on",
    description: "A few words is all you need",
  },
  {
    title: "Assistant provides insights",
    description:
      "Get help forming a plan, decide what you'll need, and evaluate trade-offs.",
  },
  {
    title: "Work on the project",
    description:
      "The assistant can answer questions and help you get it done right the first time.",
  },
];

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 20px;
  background: linear-gradient(135deg, #f6f8fa 0%, #e9f1f7 100%);
`;

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  color: black;
  margin-bottom: 1rem;
  text-align: center;
`;

const Subtitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 3rem;
  text-align: center;
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 800px;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: stretch;
  }
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(242, 133, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(242, 133, 0, 0.15);
  }

  @media (min-width: 768px) {
    flex: 1;
  }
`;

const StepNumber = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  color: #ffffff;
  background-color: #f28500;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

const StepContent = styled.div`
  text-align: center;
`;

const StepTitle = styled.h4`
  font-size: 1.25rem;
  font-weight: 600;
  color: #f28500;
  margin-bottom: 0.5rem;
`;

const StepDescription = styled.p`
  font-size: 1rem;
  color: #666;
`;

export default HowItWorks;
