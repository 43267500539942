import React, { useRef, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useSpring, animated } from "react-spring";
import { useNavigate } from "react-router-dom";

export const StartersExplorer = ({ starterKitRef }) => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  const fadeInUp = useSpring({
    opacity: visible ? 1 : 0,
    transform: visible ? "translateY(0px)" : "translateY(40px)",
    config: { mass: 1, tension: 280, friction: 60 },
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setVisible(entry.isIntersecting);
      },
      {
        threshold: 0.2,
        rootMargin: "0px 0px -100px 0px",
      }
    );

    if (starterKitRef.current) {
      observer.observe(starterKitRef.current);
    }

    return () => {
      if (starterKitRef.current) {
        observer.unobserve(starterKitRef.current);
      }
    };
  }, [starterKitRef]);

  const startChatFromKit = (input) => {
    console.log("input", input);
    const sanitizedPrompt = input.replace(/\s+/g, "-");
    const encodedPrompt = encodeURIComponent(sanitizedPrompt);
    navigate(`/starters/${encodedPrompt}`);
  };

  return (
    <Container ref={starterKitRef}>
      <animated.div style={fadeInUp}>
        <Header>Starter Packs</Header>
        <Description>Try one of the example starters</Description>
        <CardContainer>
          {starterPacks.map((pack, index) => (
            <Card
              key={index}
              onClick={() => startChatFromKit(pack.functionArgument)}
            >
              <CardIcon>{pack.icon}</CardIcon>
              <CardTitle>{pack.title}</CardTitle>
            </Card>
          ))}
        </CardContainer>
      </animated.div>
    </Container>
  );
};

const starterPacks = [
  {
    title: "Home Improvement Genius",
    icon: "🔨",
    functionArgument: "home improvement genius",
  },
  { title: "Fix anything", icon: "🔧", functionArgument: "fix anything" },
  {
    title: "Identify a plant",
    icon: "🌿",
    functionArgument: "plant identifier",
  },

  {
    title: "Get help from the Super Handy Robot",
    icon: "🤖",
    functionArgument: "super handy robot",
  },
];
const Container = styled.section`
  padding: 80px 20px;
  background-color: #fff5e6;
`;

const Header = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  color: #f28500;
  text-align: center;
  margin-bottom: 1rem;
`;

const Description = styled.p`
  font-size: 1.25rem;
  color: #666;
  text-align: center;
  margin-bottom: 3rem;
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(242, 133, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(242, 133, 0, 0.15);
  }
`;

const CardIcon = styled.span`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

const CardTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  color: #f28500;
  text-align: center;
`;

export default StartersExplorer;
