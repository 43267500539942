export const capitalizeWords = (str) => {
  return str
    .replace(/-/g, " ") // Convert hyphens to spaces
    .split(" ") // Split string into an array of words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
    .join(" "); // Join the words back into a single string
};

export const assignABTest = () => {
  const testDetails = [
    {
      test_name: "landing_page_ab_test",
      test_description:
        "This test shows different version of the landing page to different users",
      test_cases: [
        {
          test_result: 0,
          test_case_description: "Old landing version 1",
        },
        {
          test_result: 1,
          test_case_description: "Old landing version 2",
        },
        {
          test_result: 2,
          test_case_description: "New landing",
        },
      ],
    },
  ];

  console.log("[assignABTest]");
  const test = testDetails.find(
    (test) => test.test_name === "landing_page_ab_test"
  );
  const landingPageABTest =
    test.test_cases[Math.floor(Math.random() * test.test_cases.length)]
      .test_result;
  console.log("[assignABTest] landingPageABTest", landingPageABTest);
  let testCases = [
    {
      test_result: landingPageABTest,
      test_name: test.test_name,
      test_description: test.test_description,
      test_creation_date: new Date().toISOString(), // Add test_creation_date
      test_batch_version: "1", // Add test_batch_version
    },
  ];
  console.log("[assignABTest] testCases", testCases);
  return testCases;
};
