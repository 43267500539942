import React from "react";
import styled from "styled-components";
import { PrettyButton } from "../styles/styledComponents";
import { createCheckOutSesssion } from "../functions/apiFunctions";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  width: 80%;
  max-width: 500px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const ModalHeader = styled.h3`
  margin-top: 0;
  color: #333;
`;

const ModalBody = styled.p`
  color: #666;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #0056b3;
  }
`;

const RateLimitModal = ({ hasSubscription }) => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const goToPricing = () => {
    // Navigate to the home page and include the hash for the pricing section
    navigate("/pricing");
  };
  return (
    <ModalBackdrop>
      <ModalContent>
        <ModalHeader>Out of Message Credits</ModalHeader>
        {!hasSubscription ? (
          <ModalBody>
            You've hit your usage limit for our free service tier. To continue
            without interruption, please consider upgrading to a premium
            subscription.
          </ModalBody>
        ) : (
          <ModalBody>
            It appears that you've hit your usage limit for the current billing.
            Unfortunately, you'll need to wait until the next billing cycle to
            continue using the service.
            <br />
            <br />
            Reach out to{" "}
            <a href="mailto:dooehelp@gmail.com">DooeHelp@gmail.com</a>{" "}
          </ModalBody>
        )}
        {!hasSubscription && (
          <PrettyButton
            onClick={() => goToPricing()}
            style={{ width: "180px" }}
          >
            Upgrade Now
          </PrettyButton>
        )}
      </ModalContent>
    </ModalBackdrop>
  );
};

export default RateLimitModal;
