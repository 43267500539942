import { LandingVersion0 } from "../components/landing/test0/LandingVersion0";
import { LandingVersion1 } from "../components/landing/test1/LandingVersion1";
import { LandingVersion2 } from "../components/landing/test2/LandingVersion2";
import { Helmet } from "react-helmet-async";

const DEBUG = false;

const getLandingComponent = (testResult) => {
  switch (testResult) {
    case 1:
      return <LandingVersion1 />;
    case 2:
      return <LandingVersion2 />;
    default:
      return <LandingVersion0 />;
  }
};

export const Landing = ({ abTests }) => {
  const testResult = abTests[0]?.test_result;
  return (
    <>
      <Helmet>
        <title>Dooe - An AI Assistant for home improvement</title>
        <meta
          name="description"
          content="Welcome to Dooe, your AI Assistant for home tasks."
        />
        <link rel="canonical" href="https://www.dooe.io/landing" />
      </Helmet>
      {getLandingComponent(testResult)}
    </>
  );
};
