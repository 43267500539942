import React from "react";
import styled from "@emotion/styled";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterLinks>
          {/* <FooterLink href="#">About Us</FooterLink>
          <FooterLink href="#">Privacy Policy</FooterLink>
          <FooterLink href="#">Contact Us</FooterLink> */}
        </FooterLinks>
        {/* <SocialMedia>
          <a href="https://twitter.com/josh_bickett">Twitter</a>
        </SocialMedia> */}
        <Copyright>
          © {new Date().getFullYear()} Dooe. All rights reserved.
        </Copyright>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled.footer`
  padding: 100px 0 20px 0;
  background-color: white; // Deep blue that complements the header
  color: black;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const FooterLinks = styled.div`
  display: flex;
  gap: 20px;
`;

const FooterLink = styled.a`
  color: black;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const SocialMedia = styled.div`
  display: flex;
  gap: 10px;
  color: black;
  a {
    color: black;
    text-decoration: none;
  }
`;

const Copyright = styled.p`
  text-align: center;
  width: 100%;
  margin-top: 20px;
  font-size: 14px;
`;
