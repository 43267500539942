import { useAuth0 } from "@auth0/auth0-react";
import AuthenticatedChat from "../components/chat/AuthenticatedChat";
import { Helmet } from "react-helmet-async";
import UnauthenticatedChat from "../components/chat/UnauthenticatedChat";

import { useLocation } from "react-router-dom";

import styled from "@emotion/styled";

const Chat = ({ abTests }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  const location = useLocation();
  const testResult = abTests[1]?.test_result;

  const queryParams = new URLSearchParams(location.search);
  const showLogin = queryParams.get("showLogin") === "True";

  console.log("isAuthenticated", isAuthenticated);
  console.log("isLoading", isLoading);
  console.log("showLogin", showLogin);

  if (isLoading) {
    return (
      <LoadingScreen>
        <LoadingText>Loading...</LoadingText>
      </LoadingScreen>
    );
  }

  return (
    <>
      <Helmet>
        <title>Dooe - Chat</title>
        <meta
          name="description"
          content="Chat with Dooe, your AI Assistant for home tasks."
        />
        <link rel="canonical" href="https://www.dooe.io/chat" />
      </Helmet>

      {isAuthenticated || showLogin ? (
        <AuthenticatedChat abTests={abTests} />
      ) : (
        <UnauthenticatedChat abTests={abTests} />
      )}
    </>
  );
};

const LoadingScreen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: white;
`;

const LoadingText = styled.h1`
  color: black;
  font-size: 2rem;
  font-weight: bold;
`;

export default Chat;
