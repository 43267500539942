import styled from "@emotion/styled";
import ReactMarkdown from "react-markdown";
import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Send, Image as ImageIcon, ArrowRight, ArrowLeft } from "lucide-react";
// import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import { Tooltip } from "react-tooltip";

import { getNextMessageStreamingUnauthenticated } from "../../functions/apiFunctions";
import { useGeneral } from "../../contexts/GeneralContext"; // Ensure the path is correct

import { DEBUG } from "../../functions/debug";

import { Home, LogIn } from "lucide-react";
import LoginModal from "../LoginModal";
import { ChatUI } from "./ChatUI";

// TODO: AB test the "tag line", <SmallText>Do more with the help of AI</SmallText>, vs. Get more projects done with
// less work, vs. Learn to DIY vs. "AI ToDo app"

const UnauthenticatedChat = ({ abTests }) => {
  const [messages, setMessages] = useState([]);

  const { passedImage, passedPrompt, setPassedImage, setPassedPrompt } =
    useGeneral();
  const [inputValue, setInputValue] = useState("");
  const [image, setImage] = useState(null);

  const isMobile = window.innerWidth < 768;
  const [isPanelOpen, setIsPanelOpen] = useState(!isMobile);

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  const [showLoginNotification, setShowLoginNotification] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation(); // Get the current URL location
  const navigate = useNavigate(); // Use useNavigate hook to get the navigate function

  useEffect(() => {
    if (passedImage) {
      console.log("there was a passed image", passedImage);
      setImage(passedImage);
    }
  }, [passedImage]);

  const handleSendMessage = async (pPrompt, pImage) => {
    setIsLoading(true); // Set loading to true when starting
    console.log("[handleSendMessage] pPrompt", pPrompt);
    console.log("[handleSendMessage] pImage", pImage);

    let contentArray = [];

    if (pPrompt) {
      contentArray.push({ type: "text", text: pPrompt });
    } else {
      contentArray.push({ type: "text", text: inputValue });
    }

    // If there's an image, include it in the content array
    if (image) {
      console.log("[handleSendMessage] image", image);
      contentArray.push({ type: "image_url", image_url: { url: image } });
    } else if (pImage) {
      console.log("[handleSendMessage] pImage", pImage);
      contentArray.push({ type: "image_url", image_url: { url: pImage } });
    }

    const userMessage = {
      role: "user",
      content: contentArray,
    };
    let tempMessages = [...messages, userMessage];
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInputValue("");
    setImage(null);

    if (DEBUG) console.log("----[stream]----");
    const response = await getNextMessageStreamingUnauthenticated(tempMessages);
    console.log("response.body", response.body);

    if (response.status === 429) {
      setShowLoginNotification(true);
      return;
    }

    if (!response.body) {
      console.error("No response body");
      return;
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");

    while (true) {
      setIsLoading(false);
      const { done, value } = await reader.read();
      if (done) {
        break;
      }

      const chunks = decoder.decode(value, { stream: true });
      if (DEBUG) console.log("[stream] chunk ==> ", chunks);

      setMessages((prevMessages) => {
        try {
          if (chunks) {
            const chunkArray = chunks.split("\n");
            console.log("[stream] chunksString ==> ", chunkArray);

            for (const unprocessedChunk of chunkArray) {
              console.log("[stream] unprocessedChunk ==> ", unprocessedChunk);
              if (unprocessedChunk) {
                console.log("[stream] unprocessedChunk ==> ", unprocessedChunk);
                const chunks = JSON.parse(unprocessedChunk);
                console.log("[stream] cleanChunks ==> ", chunks);
                let content = chunks?.content;
                let messageId = chunks?.message_id;

                const existingMessageIndex = prevMessages.findIndex(
                  (msg) => msg.messageId === messageId
                );
                if (existingMessageIndex !== -1) {
                  prevMessages[existingMessageIndex].content += content;
                } else {
                  prevMessages.push({
                    content,
                    role: "assistant",
                    messageId: messageId,
                  });
                }
              } else {
                console.log("[stream] empty chunk");
              }
            }

            //   if (DEBUG) console.log("[stream] content ==> ", content);
            // }
            // return [...prevMessages, ...newMessages]; // If you need to merge with new messages.
            return [...prevMessages]; // This returns the updated list of messages.
          }
        } catch (e) {
          console.error("Error parsing line", e);
          return [...prevMessages];
        }
      });
    }
  };
  const returnToHome = () => {
    console.log("Returning to home");
    navigate("/");
  };

  const startChatFromKit = (input) => {
    console.log("input", input);
    const sanitizedPrompt = input.replace(/\s+/g, "-");
    const encodedPrompt = encodeURIComponent(sanitizedPrompt);
    navigate(`/starters/${encodedPrompt}`);
  };

  const goToLogin = () => {
    navigate("/chat?showLogin=True");
  };

  useEffect(() => {
    if (passedPrompt) {
      if (passedImage) {
        handleSendMessage(passedPrompt, passedImage); // Assuming you want to send the starter as a message
      } else {
        handleSendMessage(passedPrompt); // Assuming you want to send the starter as a message
      }
      setPassedImage(null);
    }
  }, []); // Empty dependency array to run only on initial load

  const goToStartersAll = () => {
    navigate("/starters");
  };

  return (
    <Container>
      <Helmet>
        <title>Dooe Chat - An AI Assistant for home improvment </title>
        <meta
          name="description"
          content={`Dooe can help you with your home tasks. Message Dooe via the Chat!`}
        />
        <link rel="canonical" href={`https://www.dooe.io/chat`} />
      </Helmet>

      <div style={{ position: "absolute", top: 5, left: 5, zIndex: 100 }}>
        <IconButton
          onClick={returnToHome}
          aria-label="Go to home"
          data-tooltip-id="home-tooltip"
          data-tooltip-content="Go to Home"
        >
          <Home size={20} />
        </IconButton>
        <IconButton
          onClick={goToLogin}
          aria-label="Go to login"
          data-tooltip-id="login-tooltip"
          data-tooltip-content="Log In"
        >
          <LogIn size={20} />
        </IconButton>
      </div>

      <Tooltip id="home-tooltip" />
      <Tooltip id="login-tooltip" />

      {showLoginNotification && <LoginModal />}

      <ChatUI
        messages={messages}
        isPanelOpen={isPanelOpen}
        togglePanel={togglePanel}
        isLoading={isLoading}
        inputValue={inputValue}
        handleSendMessage={handleSendMessage}
        image={image}
        isAuthenticated={false}
        setImage={setImage}
        setInputValue={setInputValue}
      />
    </Container>
  );
};

export default UnauthenticatedChat;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  overflow: hidden;

  @media (max-width: 768px) {
    /* Adjust breakpoint as needed */
    position: relative; /* Allows SidePanel to overlap */
  }
`;
